<template>
  <v-container grid-list-xl fluid>
    <AppTitleBar>
      <template v-slot:title>{{ profile ? profile.display : '...' }}</template>
    </AppTitleBar>
    <v-row>
      <v-col d-flex cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <Avatar />
          </v-col>
          <v-col cols="12">
            <Profile />
          </v-col>
          <v-col cols="12">
            <UserContact />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex" cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <Account />
          </v-col>
          <v-col cols="12">
            <UserPreferences />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { AppTitleBar } from '@argon/app/components'
import { mapState, mapActions } from 'vuex'
import { NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE as APP_NAMESPACE } from '@argon/app/store'
import { Avatar, Account } from '@argon/iam/me/components'
import { UserPreferences, Profile, UserContact } from '../components'

export default {
  name: 'Me',
  components: {
    AppTitleBar,
    Avatar,
    Account,
    Profile,
    UserPreferences,
    UserContact
  },
  props: {
    reload: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(NAMESPACE, ['profile'])
  },
  methods: {
    ...mapActions(APP_NAMESPACE, ['setContextObj']),
    ...mapActions(NAMESPACE, ['getProfile'])
  },
  created() {
    this.setContextObj({ ...this.profile, type: 'user' })
    if (this.reload) {
      this.getProfile().catch(() => {})
    }
  }
}
</script>
